<template>
  <div id="topic">
    <template>
      <div class="homepage-left">
        <div class="heads">
          <p>题库纠错</p>
        </div>
        <el-form style="margin-top:30px;margin-right:30px;" ref="form" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item style="100%" label="年级:" prop="grade">
                <el-select style="width:100%" v-model="edit_area_content.grade" disabled>
                  <el-option v-for="item in gradeList" :key="item.id" :label="item.value"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item style="100%" label="学科:">
                <el-select style="width:100%" v-model="edit_area_content.subject" disabled>
                  <el-option v-for="item in subjectList" :key="item.id" :label="item.value"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="教辅:">
                <el-select style="width:100%" v-model="edit_area_content.teachingMaterialId" disabled>
                  <el-option v-for="item in teachingAidsList" :key="item.id" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="题型:">
                <el-select style="width:100%" v-model="questionBankTypeVO" @change="questionTypeChange" disabled>
                  <el-option v-for="item in questionTypeList" :key="item.questionTypeName"
                             :label="item.questionTypeName"
                             :value="item.questionTypeValue"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="题号:">
                <el-input style="width:100%" placeholder="请输入题号" v-model="edit_area_content.titleNumber" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="页码:">
                <el-input style="width:100%" placeholder="请输入页码" v-model="edit_area_content.pageNumber"
                          disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <el-tabs v-model="editAreaActiveName" style="width: 90%;margin-left: 5%;">
            <el-tab-pane label="题干" :name="edit_tags.tg">
              <div class="homepage-left-middle">
                <el-button class="homepage-left-middle-btn" @click="editorFraction">分数</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorImage">图片</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorUnderline">下划线</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorNewLine">换行</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorKeySymbol">着重号</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editAddSpace">空格</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddDivide">除号</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddGreater ">大于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddLess">小于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddAnd">和</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editTimes">乘</el-button>
                <el-button class="homepage-left-middle-btn" @click="underWavy">波浪线</el-button>
              </div>
              <div>
                <el-input type="textarea" class="editor_textarea" :rows="12" :id="edit_tags.tg" ref="tg"
                          v-model="edit_area_content.stem"
                          @keyup.enter.native="editorNewLine"
                          @keyup.space.native="editKeyupSpace"
                          :placeholder="questionBankInfo.stem"></el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane label="答案" :name="edit_tags.da">
              <div class="homepage-left-middle">
                <el-button class="homepage-left-middle-btn" @click="editorFraction">分数</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorImage">图片</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorUnderline">下划线
                </el-button>
                <el-button class="homepage-left-middle-btn" @click="editorNewLine">换行</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorKeySymbol">着重号</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editAddSpace">空格</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddDivide">除号</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddGreater ">大于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddLess">小于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddAnd">和</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editTimes">乘</el-button>
                <el-button class="homepage-left-middle-btn" @click="underWavy">波浪线</el-button>
              </div>
              <div>
                <el-input type="textarea" class="editor_textarea" :rows="12" :id="edit_tags.da" ref="da"
                          v-model="edit_area_content.answer"
                          @keyup.enter.native="editorNewLine"
                          @keyup.space.native="editKeyupSpace"></el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane label="解析" :name="edit_tags.jx">
              <div class="homepage-left-middle">
                <el-button class="homepage-left-middle-btn" @click="editorFraction">分数</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorImage">图片</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorUnderline">下划线
                </el-button>
                <el-button class="homepage-left-middle-btn" @click="editorNewLine">换行</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorKeySymbol">着重号</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editAddSpace">空格</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddDivide">除号</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddGreater ">大于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddLess">小于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddAnd">和</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editTimes">乘</el-button>
                <el-button class="homepage-left-middle-btn" @click="underWavy">波浪线</el-button>
              </div>
              <div>
                <el-input type="textarea" class="editor_textarea" :rows="12" :id="edit_tags.jx" ref="jx"
                          v-model="edit_area_content.analysis"
                          @keyup.enter.native="editorNewLine"
                          @keyup.space.native="editKeyupSpace"
                          :value="edit_area_content.analysis"></el-input>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="homepage-midden">
        <div class="headss">
          <p>图片展示</p>
        </div>
        <div style="width:100%;height:95%" id="imageAreaMain">
          <img
            v-if="edit_area_content.images && edit_area_content.images.length > 0"
            :src="edit_area_content.images[0].image" :key="edit_area_content.images.page"
            :id="'img_'+edit_area_content.images[0].page"
            style="max-width: 1px;"/>
          <canvas id="show_image_canvas" :height="canvasMaxHeight"
                  :width="canvasMaxWidth" style=" height: auto;
            max-width: 100%;
            max-height: 100%;">
          </canvas>
        </div>
      </div>
      <div class="homepage-right">
        <div class="headss">
          <p>题干预览</p>
        </div>
        <div class="homepage-right-header" style="overflow: auto;width:100%;height:33%">
          <div style="width:100%;height:100%">
            <div class="preview-area">
              <div v-html="edit_area_content.stem" style="line-height:18px;letter-spacing: 1px;"></div>
            </div>
          </div>
        </div>
        <div class="headss">
          <p>答案预览</p>
        </div>
        <div class="homepage-right-middle" style="overflow: auto;">
          <div>
            <div class="preview-area">
              <div v-html="edit_area_content.answer" style="line-height: 15px;letter-spacing: 1px;"></div>
            </div>
          </div>
        </div>
        <div class="headss">
          <p>解析预览</p>
        </div>
        <div class="homepage-right-footer" style="overflow: auto;">
          <div>
            <div class="preview-area">
              <div v-html="edit_area_content.analysis" style="line-height: 18px;letter-spacing: 1px;"></div>
            </div>
          </div>
        </div>
        <div class="home-right-btn">
          <!-- <el-button class="check" @click="checkPopup" :loading="checkQuestion">题目查重</el-button> -->
          <el-button class="complete" @click="addItemBankEvents" :loading="addItemBankLoading">完成</el-button>
        </div>
      </div>
    </template>
    <!--area image upload-->
    <template>
      <el-upload class="avatar-uploader" action="" :show-file-list="false"
                 accept="image/png,image/jpeg" id="handler-area-image-upload">
      </el-upload>
    </template>
    <!--input image upload-->
    <template>
      <el-upload class="avatar-uploader" action="" :show-file-list="false"
                 :before-upload="beforeInputImageAvatarUpload"
                 :on-change="inputImageChangeEvent"
                 accept="image/png,image/jpeg" id="input-area-image-upload">
      </el-upload>
    </template>
    <!--分数-->
    <template>
      <el-dialog
        title="提示"
        :visible.sync="fractionDialogVisible"
        width="480px"
        :before-close="()=>{fractionDialogVisible=false}">
        <div>
          分子:
          <el-input
            style="width:136px;"
            v-model="fraction.molecule"
            placeholder="请输入分子">
          </el-input>
          &nbsp;&nbsp;&nbsp;&nbsp;分母:
          <el-input
            style="margin-left: 10px;width:136px;"
            v-model="fraction.denominator"
            placeholder="请输入分母">
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="fractionDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="fractionActionOk">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 查重弹框 -->
    <template>
      <el-dialog
        title=""
        :show-close="false"
        :visible.sync="centerDialogVisible"
        width="70%"
        center>
        <div class="checkContent" id="checkText">
          <div class="checkLeft">
            <div class="checkTitle"><p>原题</p></div>
            <div class="checkText">
              <p v-html="edit_area_content.stem" style="line-height: 25px;letter-spacing: 1px;"></p>
            </div>
          </div>
          <div class="checkRight">
            <div class="checkTitle"><p>相似题</p></div>
            <div class="checkText" style="line-height: 25px;letter-spacing: 1px;">
              <div class="context" v-for="(item, index) in checkQuestionList" :key="index">
                <div class="headers">
                  <!-- <el-checkbox style="transform: scale(1.3)" v-model="item.repeatId" @change="handleCheckedChange"></el-checkbox> -->
                  <el-radio-group v-model="checked">
                    <el-radio :label="item.bankId" @click.native.prevent="clickitem(item)">
                      <!-- <span class="similar">相似度：{{item.score.toFixed(2)}}%</span> -->
                      <span class="similar">第{{ index + 1 }}题</span>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="contents" v-html="item.stem"></div>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button style="width: 120px" @click="cancelBtn">取 消</el-button>
          <el-button style="background-color: #1EB180;border: #1EB180 1px solid;width: 120px" type="primary"
                     @click="submitBtn">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { definedKeySymbolStyle, insertText, KEY_SYMBOL_CLASS_NAME } from '@/utils/common.fun'
import DrawTool from '@/drawTool'
import { getMaterialList } from '@/api/material'
import { getGradeName, getQuestionStatusName, getQuestionTypeName, getSubjectName, grade, subject } from '@/utils/enums'
import { SETTING } from '@/config/setting'
import {
  questionBankCorrect,
  getQuestionBankDetails,
  imageUpload,
  questionTypeList
} from '@/api/question-bank'

const YANJU_CHARS = {
  NUMERATOR: '*yanju_numerator*', // 分子
  DENOMINATOR: '*yanju_denominator*' // 分母
}
// 分数字符串
const STR_FRACTION = '\\(\\frac{' + YANJU_CHARS.NUMERATOR + '}{' + YANJU_CHARS.DENOMINATOR + '}\\)'
// 下划线
const STR_UNDERLINE = '\\(\\underline{~~~~~}\\)'
// 题干
const TG_NAME = 'tg'
// 答案预览
const DA_NAME = 'da'
// 解析
const JX_NAME = 'jx'
export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },
  created () {
    const id = this.$route.params.id
    if (id == null || id === '') {
      this.$router.push({ path: '/List/edit' })
    } else {
      getQuestionBankDetails(id).then(response => {
        this.spinning = false
        this.edit_area_content = response.data
        // console.log(' this.edit_area_content', this.edit_area_content)
        this.questionExamineStatus = this.edit_area_content.status
        this.questionBankTypeVO = response.data.questionType
        // this.showBigImg()
        this.checked = response.data.bankId
        this.enterStem = response.data.stem
        this.enterAnswer = response.data.answer
        this.enterAnalysis = response.data.analysis
        this.showBigImg()
        this.getQuestionTypeList(response.data.grade, response.data.subject)
      }).catch(error => {
        console.log(error)
        this.spinning = false
      })
    }
    this.subjectChangeEvent()
  },
  filters: {
    getQuestionTypeName (id) {
      return getQuestionTypeName(id)
    },
    getGradeName (id) {
      return getGradeName(id)
    },
    getSubjectName (id) {
      return getSubjectName(id)
    },
    getQuestionStatusName (id) {
      return getQuestionStatusName(id)
    }
  },
  data () {
    return {
      // 特殊字符对话框显示
      fractionDialogVisible: false,
      showSpecialSymbols: false,
      addItemBankLoading: false,
      checkQuestion: false,
      centerDialogVisible: false,
      questionBankInfo: {},
      dialogVisible: false,
      canvasMaxWidth: 0,
      canvasMaxHeight: 0,
      userLoginForm: {
        // user login box
        showUserLoginBox: false,
        userPhone: null,
        smsCode: null,
        errorMsg: null
      },
      // loading实例
      loadingObject: null,
      settings: {
        // 最大操作区域tab个数
        maxHandlerAreaTabCount: SETTING.NUMBER.TWO,
        // 最大上传图片大小
        maxImageSize: SETTING.NUMBER.N1024 * SETTING.NUMBER.N1024 * 3
      },
      labelPosition: 'right',
      // 下拉选择值
      edit_area_conten: {
        // 年级
        grade: 'FIFTH_GRADE',
        gradeId: 'selectGrade',
        // 学科
        subject: 'MATHEMATICS',
        subjectId: 'selectSubject',
        // 题型
        questionType: null,
        questionTypeId: 'questionType',
        // 题号
        titleNumber: 'titleNumber',
        // 页码
        pageNumber: 'pageNumber',
        // 教辅
        teachingMaterialName: null,
        teachingMaterialId: 'teachingMaterialId'
      },
      edit_tags: {
        tg: TG_NAME,
        da: DA_NAME,
        jx: JX_NAME
      },
      // 年级列表
      gradeList: grade,
      // 教辅列表
      teachingAidsList: [],
      // 学科列表
      subjectList: subject,
      // 题库列表
      questionTypeList: [],
      // 编辑区内容保存
      edit_area_content: {
        // 题干内容
        stem: null,
        // 答案内容
        answer: null,
        // 解析内容
        analysis: null
      },
      // 分数
      fraction: {
        // 分子
        molecule: null,
        // 分母
        denominator: null
      },
      // 编辑区
      editAreaActiveName: TG_NAME,
      // 操作区
      handlerAreaActiveName: 'n1',
      // 页面number列表
      pageNumberList: [],
      // 保存DT对象
      dbObjectList: [],
      // 查重题目列表
      checkQuestionList: [],
      checked: '', // 查重id
      propertyId: '',
      enterStem: '', // 刚进入页面未修改的题干
      enterAnswer: '', // 刚进入页面未修改的答案
      enterAnalysis: '', // 刚进入页面未修改的解析
      isUpdate: false, // 是否修改过题干 答案 解析
      questionBankTypeVO: undefined
    }
  },
  watch: {
    edit_area_content: {
      handler (newval, oldVal) {
        const that = this
        that.$nextTick(function () {
          that.commonsVariable.MathQueue()
        })
      },
      deep: true
    }
  },
  methods: {
    clickitem (item) {
      if (item.bankId === this.checked) {
        this.checked = '' // 如果点击的对象是area就将v-model的值清空 radio状态为空
        this.propertyId = ''
      } else {
        this.checked = item.bankId // 否则就把点击的值赋值给area 即绑定的radio
        this.propertyId = item.id
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    showBigImg () {
      const self = this
      self.canvasMaxWidth = document.getElementById('imageAreaMain').clientWidth
      self.canvasMaxHeight = document.getElementById('imageAreaMain').clientHeight
      self.dialogVisible = true
      // 需要延迟处理,不然无法获取到DOM节点
      self.imageLoading = false
      const imgObject = new Image()
      imgObject.src = self.edit_area_content.images[0].image
      imgObject.onload = function () {
        const img = document.getElementById('img_' + self.edit_area_content.images[0].page)
        const imgHeight = imgObject.height
        const imgWidth = imgObject.width
        let nHeight = imgHeight
        let nWeight = imgWidth
        let rate = 1
        // 缩放图片
        if (imgWidth > self.canvasMaxWidth) {
          nWeight = self.canvasMaxWidth
          rate = imgWidth / self.canvasMaxWidth
          nHeight = imgHeight / rate
        }
        const canvas = document.getElementById('show_image_canvas')
        canvas.height = nHeight > self.canvasMaxHeight ? nHeight : self.canvasMaxHeight
        const ctx = canvas.getContext('2d')
        // 清空画布
        ctx.clearRect(0, 0, self.canvasMaxWidth, self.canvasMaxHeight)
        // 获取到坐标
        let pos = []
        self.edit_area_content.coordinate.forEach(item => {
          if (item.page === self.edit_area_content.images[0].page) {
            pos = item.coordinate
          }
        })
        ctx.drawImage(img, 0, 0, nWeight, nHeight)
        ctx.beginPath()
        ctx.strokeStyle = 'red'
        pos.forEach(item => {
          ctx.rect(item.x_min / rate, item.y_min / rate, (item.x_max - item.x_min) / rate, (item.y_max - item.y_min) / rate)
        })
        ctx.stroke()
      }
    },
    // 获取题型列表
    getQuestionTypeList (grade, subject) {
      this.questionTypeList = []
      questionTypeList(grade, subject).then(res => {
        if (res.code === 0) {
          this.questionTypeList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 查重弹框取消按钮
    cancelBtn () {
      this.centerDialogVisible = false
    },
    // 查重弹框确认按钮
    submitBtn () {
      if (!this.checked) {
        this.checked = this.edit_area_content.bankId
      }
      this.editQuestion()
      this.centerDialogVisible = false
    },
    addItemBankEvents () {
      const self = this
      if (self.enterStem === self.edit_area_content.stem && self.enterAnswer === self.edit_area_content.answer && self.enterAnalysis === self.edit_area_content.analysis) {
        self.isUpdate = false
      } else {
        self.isUpdate = true
      }
      self.$nextTick(function () {
        self.commonsVariable.MathQueue()
      })
      this.editQuestion()
    },
    // 题型选择事件
    questionTypeChange (e) {
      this.questionBankTypeVO = e
    },
    editQuestion () {
      const self = this
      const data = {
        // 学科
        // subject: self.edit_area_content.subject,
        // 年级
        // grade: self.edit_area_content.grade,
        // 题型
        // questionType: self.questionBankTypeVO,
        // 题目来源
        // titleSource: 'TEACHING_MATERIAL',
        // 教材ID
        // teachingMaterialId: self.edit_area_content.teachingMaterialId,
        // 题号
        // titleNumber: self.edit_area_content.titleNumber,
        // 页码
        // pageNumber: self.edit_area_content.pageNumber,
        // 题干(HTML类型)
        stem: self.edit_area_content.stem,
        // 答案(HTML类型)
        answer: self.edit_area_content.answer,
        // 解析(HTML类型)
        analysis: self.edit_area_content.analysis,
        // ID
        id: self.edit_area_content.id
        // 查重id
        // bankId: self.checked,
        // propertyId: self.propertyId,
        // 是否修改过题干
        // isUpdate: self.isUpdate
      }
      self.addItemBankLoading = true
      questionBankCorrect(data).then(response => {
        self.addItemBankLoading = false
        if (response.code === SETTING.NUMBER.ZERO) {
          this.$message({ type: 'success', message: '纠正成功' })
          this.$router.push('/List')
        } else {
          this.$message({ type: 'error', message: '纠正失败:' + response.msg })
        }
      })
    },
    // 年级change事件
    gradeChangeEvent () {
      if (this.edit_area_conten.grade != null && this.edit_area_conten.subject != null) {
        this.getMaterialApi()
      }
    },
    // 学科change事件
    subjectChangeEvent () {
      if (this.edit_area_conten.grade != null && this.edit_area_conten.subject != null) {
        this.getMaterialApi()
      }
    },
    // // 教辅change事件
    // teachingMaterialNameEvent () {
    //   this.getMaterialApi()
    // },
    // 获取教辅列表接口
    getMaterialApi () {
      getMaterialList(this.edit_area_conten.grade, this.edit_area_conten.subject).then(res => {
        if (this.checkApiData(res)) {
          const self = this
          const data = res.data
          self.teachingAidsList = []
          self.edit_area_conten.teachingMaterialId = null
          data.forEach((item) => {
            self.teachingAidsList.push({
              id: parseInt(item.id),
              name: item.name + '(' + item.printingTimes + ')'
            })
          })
        }
      })
    },
    // 校验网络请求
    checkApiData (res) {
      if (res.code !== SETTING.NUMBER.ZERO) {
        this.$message({ type: 'error', message: res.msg })
        return false
      }
      return true
    },
    // 获取焦点
    itemFocus (id) {
      document.getElementById(id).focus()
    },
    // 编辑器之分数事件
    editorFraction () {
      // 清除数据
      this.fraction.molecule = null
      this.fraction.denominator = null
      // 弹出输入分数的框
      this.fractionDialogVisible = true
    },
    // 分数确认操作
    fractionActionOk () {
      if (this.fraction.molecule == null || this.fraction.denominator.length == null) {
        this.$message({ type: 'error', message: '请输入分子/分母' })
        return
      }
      let str = STR_FRACTION
      str = str.replace(YANJU_CHARS.NUMERATOR, this.fraction.molecule)
      str = str.replace(YANJU_CHARS.DENOMINATOR, this.fraction.denominator)
      this.contentHandler(str)
      this.fractionDialogVisible = false
      this.itemFocus(this.editAreaActiveName)
    },
    // 编辑器之图片事件
    editorImage () {
      // 图片上传触发
      document.getElementById('input-area-image-upload').getElementsByTagName('input')[SETTING.NUMBER.ZERO].click()
      this.itemFocus(this.editAreaActiveName)
    },
    // 编辑器之下划线事件
    editorUnderline () {
      this.contentHandler(STR_UNDERLINE)
    },
    // 添加空格
    editAddSpace () {
      this.contentHandler('\\(\\ \\)')
    },
    // 键盘添加空格
    editKeyupSpace (e) {
      e = e || window.event
      const ctrlKey = e.ctrlKey
      const keyCode = e.keyCode || e.which || e.charCode
      if (ctrlKey && keyCode === 32) {
        this.contentHandler('\\(\\ \\)')
        return false
      }
    },
    // 添加除号
    editAddDivide () {
      this.contentHandler('\\(\\div\\)')
    },
    // 换行事件
    editorNewLine () {
      this.contentHandler('<br />')
    },
    getSelectedText () {
      // 先获取到选中文本
      let text = ''
      let content = ''
      let obj = null
      switch (this.editAreaActiveName) {
        case TG_NAME:
          content = this.edit_area_content.stem
          obj = document.getElementById(TG_NAME)
          break
        case DA_NAME:
          content = this.edit_area_content.answer
          obj = document.getElementById(DA_NAME)
          break
        case JX_NAME:
          content = this.edit_area_content.analysis
          obj = document.getElementById(JX_NAME)
          break
      }

      if (document.selection) {
        text = document.selection.createRange().text.toString()
      } else if (obj.selectionStart) {
        text = obj.value.substring(obj.selectionStart, obj.selectionEnd)
      } else {
        text = window.getSelection().toString()
      }
      if (text.trim().length === 0) {
        return [null, content]
      }
      return [text, content]
    },
    // 波浪线
    underWavy () {
      const text = this.getSelectedText()[0]
      // 加上样式
      const hl = '<span style="text-decoration: underline wavy black">' + text + '</span>'
      this.contentHandler(hl)
    },
    // 着重号
    editorKeySymbol () {
      const result = this.getSelectedText()
      const text = result[0]
      const content = result[1]
      const style = definedKeySymbolStyle()
      // 校验是否已经添加过style
      if (content.indexOf('.' + KEY_SYMBOL_CLASS_NAME) === -1) {
        this.contentHandler(style)
      }
      let hl = ''
      for (let i = 0; i < text.length; i++) {
        hl += '<span class="' + KEY_SYMBOL_CLASS_NAME + '">' + text[i] + '</span>'
      }
      this.contentHandler(hl)
    },
    // 大于等于号
    editAddGreater () {
      this.contentHandler('\\(\\geq\\)')
    },
    // 小于等于号
    editAddLess () {
      this.contentHandler('\\(\\leq\\)')
    },
    // times
    editTimes () {
      this.contentHandler('\\(\\times\\)')
    },
    // and
    editAddAnd () {
      this.contentHandler('\\(\\&\\)')
    },
    // 处理编辑区内容插入
    contentHandler (str) {
      const textareaObject = document.getElementById(this.editAreaActiveName)
      insertText(textareaObject, str)
      const newVal = textareaObject.value || null
      this.itemFocus(this.editAreaActiveName)
      textareaObject.value = newVal
      switch (this.editAreaActiveName) {
        case this.edit_tags.jx:
          this.edit_area_content.analysis = newVal
          break
        case this.edit_tags.da:
          this.edit_area_content.answer = newVal
          break
        case this.edit_tags.tg:
          this.edit_area_content.stem = newVal
          break
      }
    },
    // 录入区上传前校验
    beforeInputImageAvatarUpload (file) {
      if (this.checkUploadImage(file)) {
        // 图片上传操作
      }
      // 不使用upload自带的上传方式，而是使用axios，所以阻止upload自带的上传
      return false
    },
    // 图片上传成功事件
    inputImageChangeEvent (file, fileList) {
      const self = this
      self.showLoading('图片上传中...')
      // oss上传图片
      const fs = file.raw
      const newFileName = this.$common.getMyd() + '/' + this.$common.getUuid() + '.' + this.$common.getImageSuffix4ImageType(fs.type)
      self.$oss.put(newFileName, fs).then(function (r1) {
        self.closeLoading()
        // 上传成功
        self.$message({ type: 'success', message: '图片上传成功' })
        // 写入文本中
        const imageUrl = SETTING.URL.IMAGE_URL + '/' + newFileName
        const str = '<img src=\'' + imageUrl + '\' alt=\'题库\'/>'
        self.contentHandler(str)
      }).catch(err => {
        self.closeLoading()
        console.error(err)
        // 上传失败
        self.$message({ type: 'error', message: '图片上传失败' })
      })
    },
    // 图片上传前校验图片
    checkUploadImage (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isOutMaxImageSize = file.size / this.settings.maxImageSize < SETTING.NUMBER.ONE
      if (!isJPG && !isPNG) {
        this.$message({ type: 'error', message: '上传头像图片只能是 JPG 或 PNG 格式!' })
        this.pageNumberList.pop()
        return false
      } else if (!isOutMaxImageSize) {
        this.$message({ type: 'error', message: '上传头像图片大小不能超过 ' + (this.settings.maxImageSize / SETTING.NUMBER.N1024 / SETTING.NUMBER.N1024) + 'MB!' })
        this.pageNumberList.pop()
        return false
      } else {
        return true
      }
    },
    // 显示loading
    showLoading (msg) {
      console.log('Loading')
    },
    // 关闭loading
    closeLoading () {
      if (this.loadingObject != null) {
        this.loadingObject.close()
      }
    },
    // 图片显示
    imagePreview: function (file) {
      const self = this
      // 上传至OSS
      const newFileName = 'teaching-material/' + self.edit_area_content.teachingMaterialId + '/' + self.pageNumber + '.' + self.$common.getImageSuffix4ImageType(file.type)
      self.$oss.put(newFileName, file).then(function (r1) {
        self.closeLoading()
        // 上传成功
        self.$message({ type: 'success', message: '图片上传成功' })
        // 上传至DB
        imageUpload({
          questionImage: SETTING.URL.IMAGE_URL + '/' + newFileName,
          page: self.pageNumber,
          teachingMaterialId: self.edit_area_content.teachingMaterialId
        }).then(response => {
          if (response.code !== SETTING.NUMBER.ZERO) {
            self.$message({ type: 'error', message: response.msg })
          }
        })
        // 定义一个文件阅读器
        const reader = new FileReader()
        // 文件装载后将其显示在图片预览里
        reader.onload = function (e) {
          const displayAreaObject = document.getElementById('display_area_' + self.pageNumber)
          const regionCanvasObject = document.getElementById('region_canvas_' + self.pageNumber)
          const imageCanvasObject = document.getElementById('image_canvas_' + self.pageNumber)
          const canvasLayerObject = document.getElementById('canvas_layer_' + self.pageNumber)

          function syncPosition () {
            self.alreadySelectCoordinateCount = SETTING.NUMBER.ZERO
            const data = self.dbObjectList[self.pageNumber].pick() || []
            if (data.length > SETTING.NUMBER.ZERO) {
              self.coordinateList[self.pageNumber] = data
            }
          }

          const dt = new DrawTool(displayAreaObject, regionCanvasObject, imageCanvasObject, canvasLayerObject, syncPosition)

          // 缓存到列表中
          self.dbObjectList[self.pageNumber] = dt
          dt.clearCanvas()
          dt.loadImage(this.result).then(() => {
            // 同步加载完毕后执行
            console.log('do nothing')
          }).catch(() => {
            self.$message({ type: 'error', message: '同步加载失败,请刷新页面重新操作' })
          })
        }
        reader.readAsDataURL(file)
      }).catch(err => {
        self.closeLoading()
        console.error(err)
        // 上传失败
        self.$message({ type: 'error', message: '图片上传失败' })
      })
    }
  }

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  overflow: auto !important;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

#topic {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}

.homepage-left {
  width: 31%;
  height: 100%;
  border: 1px solid #CCF4E0;
  border-radius: 5px;
}

.homepage-midden {
  width: 35%;
  height: 100%;
  border: 1px solid #CCF4E0;
  border-radius: 5px;
}

.el-textarea__inner {
  line-height: 1.3;
}

.homepage-right {
  width: 30%;
  height: 100%;
  box-sizing: border-box;
}

.homepage-right-header {
  width: 100%;
  height: 30%;
  border: 1px solid #CCF4E0;
  margin-bottom: 10px;
  white-space: normal;
  overflow: hidden;
}

.homepage-right-middle {
  width: 100%;
  height: 22%;
  border: 1px solid #CCF4E0;
  flex-grow: 1;
  margin-bottom: 10px;
}

.homepage-right-footer {
  width: 100%;
  height: 22%;
  border: 1px solid #CCF4E0;
}

.header {
  width: 100%;
  height: 50px;
  background: red;
}

.heads {
  width: 100%;
  height: 40px;
  background: #CCF4E0;
}

.heads p {
  line-height: 40px;
  margin-left: 15px;
  color: #00B87B;
  margin-right: 30px;
}

.headss {
  width: 100.4%;
  height: 40px;
  background: #CCF4E0;
}

.headss p {
  line-height: 40px;
  margin-left: 15px;
  color: #00B87B;
  margin-right: 30px;
}

.complete {
  width: 100%;
  color: white;
  background: #1EB180;
  margin-top: 3%;
}

.homepage-right-btn {
  padding: 10px;
}

.Operation {
  height: 75%;
  border: 0.5px solid #CCF4E0;
}

.homepage-middle-middle {
  margin: 10px auto;
  height: 90px;
  overflow: hidden;
  overflow-y: scroll;
}

.homepage-middle-btn {
  display: flex;
  justify-content: space-between;
}

.homepage-middle-btn1 {
  width: 40%;
  border: 1px solid #1EB180;
  color: #1EB180;
}

.homepage-middle-btn2 {
  width: 40%;
  background: #1EB180;
  color: white;
}

.el-tabs__item {
  color: #1EB180;
}

.el-tabs__item.is-active {
  color: #333333;
}

.el-tabs__active-bar {
  background: #1EB180;
}

.homepage-left-middle-btn {
  color: #1EB180;
  border: 1px solid #1EB180;
}

.el-textarea__inner {
  line-height: 1.5;
}

/* .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--top .el-tabs__item.is-top:nth-child(2){
  padding-left: 35px;
} */

.el-tabs__active-bar {
  background: #1EB180;
}

.el-tabs__nav-wrap::after {
  background: #CCF4E0;
}

.homepage-page {
  display: flex;
  margin-top: 40px;
  margin-left: 20%;
}

.homepage-page p {
  text-align: center;
  margin: 0 15px;
}

.nums {
  width: 60px;
  height: 30px;
  border: 0 solid white;
  border-bottom: 1px solid #CCC;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
}

.page_handle {
  color: #1EB180;
  font-size: 30px;
  margin-top: 7px;
  cursor: pointer;
}

#page-action-ok {
  width: 80%;
  background: #1EB180;
  border: 1px solid #1EB180;
  color: white;
}

.hide {
  display: none
}

.btn {
  cursor: pointer
}

.editor_textarea {
  margin: 12px auto;
  height: 80%;
  width: 100%;
}

.preview-area {
  padding: 4px;
  width: 100%;
  height: 100%;
}

.preview-area img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.home-right-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check {
  width: 35%;
  color: white;
  background: #1EB180;
  margin-top: 3%;
}

.checkContent {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.checkTitle {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #1EB180;
  font-weight: 600;
  background-color: #E8F7F2;
}

.checkTitle p {
  margin: 0 20px;
}

.checkLeft {
  width: 49%;
  border: 1px solid #1EB180;
  border-radius: 10px;
  overflow: hidden;
}

.checkRight {
  width: 49%;
  border: 1px solid #1EB180;
  border-radius: 10px;
  overflow: hidden;
}

.checkLeft .checkText {
  padding: 20px;
  height: 500px;
  /* overflow-y: scroll; */
  overflow: auto;
}

.checkRight .checkText {
  padding: 10px;
  background-color: #EEEEEE;
  height: 520px;
  /* overflow-y: scroll; */
  overflow: auto;
}

.checkLeft .checkText img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.checkRight .checkText img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.checkRight .checkText .context {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.headers {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.headers:nth-last-child(1) {
  margin-bottom: 0;
}

.similar {
  font-size: 14px;
  color: #1EB180;
  font-weight: 600;
  /* margin-left: 10px; */
  /* margin-bottom: 2px; */
}

.contents {
  color: #666;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1EB180;
  border-color: #1EB180;
}

.el-checkbox__inner {
  border-radius: 50%;
}

.el-radio__input.is-checked .el-radio__inner {
  background-color: #1EB180;
  border-color: #1EB180;
}
</style>
